import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  calculateDeliveryDate,
  workingHoursCutoff,
} from "../../utils/deliveryDate";
import { useTranslation } from "react-i18next";
import { getTransportPrice, transportMethods } from "../../utils/global_const";
import { ProductContext } from "../../context/ProductContext";
import { useSelector } from "react-redux";

const Container = styled.div`
  border: 1px solid var(--bg-200);
  border-radius: 8px;
  background-color: var(--bg-200);
  width: 100%;
`;
const Wrapper = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Option = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  padding: 12px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? "var(--primary-100)" : "var(--bg-100)")};
  border-radius: 8px;
  background-color: ${({ isSelected }) =>
    isSelected ? "#68a67d37" : "var(--bg-100);"};
  cursor: pointer;
  margin-bottom: 10px;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &:hover {
    border-color: var(--success-color);
  }
  @media (min-width: 768px) {
    padding: 24px;
  }
`;
const InnerOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: 768px) {
    width: 90%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--text-100);
`;

const Details = styled.span`
  font-size: var(--font-size-base);
  margin-top: 5px;
`;

const Price = styled.span`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const Button = styled.button`
  margin: 14px 0;
  width: 100%;
  padding: 10px 20px;
  background-color: var(--primary-200);
  color: var(--bg-200);
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-100);
  }
  @media (min-width: 768px) {
    width: 50%;
  }
`;

const Step2 = ({ onNext, address }) => {
  const { t } = useTranslation();
  const { currencyTag } = useContext(ProductContext);
  const [selectedMethod, setSelectedMethod] = useState(transportMethods[0]);
  const cartItems = useSelector((state) => state.cart.itemsList);
  let totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleSelect = (method) => {
    setSelectedMethod(method);
  };

  const handleSubmit = () => {
    if (selectedMethod) {
      onNext(selectedMethod);
    }
  };

  return (
    <>
      <ExampleContainer>
        <ExampleTitle>{t("CHECKOUT.DELIVER_TO")}</ExampleTitle>
        <ExampleText>
          {`${address.street} ${address.street_number}`}
        </ExampleText>
        <ExampleText>{`${address.city}, ${address.postal_code}`}</ExampleText>
        <ExampleText>{address.country}</ExampleText>
      </ExampleContainer>
      <Container>
        <Wrapper>
          <Title>{t("CHECKOUT.DELIVERY_OPTIONS")}</Title>
          {transportMethods.map((method, index) => (
            <Option
              key={index}
              isSelected={selectedMethod.name === method.name}
              onClick={() => handleSelect(method)}
            >
              <InnerOption>
                <Info>
                  <Name>{method.name}</Name>
                  <Details>
                    {t("CHECKOUT.ESTIMATED_DELIVERY")}{" "}
                    {calculateDeliveryDate(method.days)}
                  </Details>
                </Info>
                <Price>
                  {getTransportPrice(method, totalQuantity) === 0
                    ? t("CHECKOUT.FREE")
                    : `${currencyTag}${getTransportPrice(
                        method,
                        totalQuantity
                      ).toFixed(2)}`}
                </Price>
              </InnerOption>
              {selectedMethod === method.name && (
                <p style={{ fontSize: "var(--font-size-small)" }}>
                  {t("CHECKOUT.PLACE_ORDER_BEFORE")} {workingHoursCutoff}h{" "}
                  {t("CHECKOUT.SAME_DAY_SHIPPING")}
                </p>
              )}
            </Option>
          ))}
        </Wrapper>
      </Container>
      <ButtonContainer>
        <Button onClick={handleSubmit} disabled={!selectedMethod}>
          {t("BUTTONS.CONTINUE")}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default Step2;

const ExampleContainer = styled.div`
  background-color: var(--bg-200);
  padding: 15px;
  border-left: 4px solid var(--accent-200);
  border-radius: 5px;
  width: calc(94% - 4px);
  margin-bottom: 14px;
  @media (min-width: 768px) {
    width: 700px;
  }
`;

const ExampleTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ExampleText = styled.p`
  margin-bottom: 6px;
`;
