import i18next from "i18next";
import { useNavigate } from "react-router-dom";

export function useNavigation() {
  const navigate = useNavigate();

  function goToHome() {
    return "/";
  }
  function goToFeatured() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/featured`;
  }
  function goToProduct(category, title) {
    const currentLanguage = i18next.language;
    const formattedCategory = encodeURIComponent(
      category.toString().toLowerCase().replace(/ /g, "-")
    );
    const formattedTitle = encodeURIComponent(
      title.toString().toLowerCase().replace(/ /g, "-")
    );

    return `/${currentLanguage}/${formattedCategory}-${formattedTitle}`;
  }
  function goToCategory(category) {
    const currentLanguage = i18next.language;
    const formattedCategory = encodeURIComponent(
      category.toString().toLowerCase().replace(/ /g, "-")
    );

    return `/${currentLanguage}/nicotine-pouches/${formattedCategory}`;
  }
  function goToCheckout() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/checkout`;
  }
  function goToShop() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/nicotine-pouches`;
  }
  function goToLogin() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/signin`;
  }
  function goToRegister() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/signup`;
  }
  function goToVerification() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/verification`;
  }
  function goToForgotPassword() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/password`;
  }

  function goToThankYou() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/thank-you`;
  }
  //PRODUCT
  function goToBestSeller() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/best-seller`;
  }
  //PROFILE
  function goToAccount() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account`;
  }
  function goToProfile() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account/profile`;
  }
  function goToSavedCards() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account/saved-cards`;
  }
  function goToAddressBook() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account/address-book`;
  }
  function goToOrderHistory() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account/orders`;
  }
  function goToAddAddressBook() {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account/address-book/new`;
  }
  function goToEditAddressBook(id) {
    const currentLanguage = i18next.language;
    return `/${currentLanguage}/account/address-book/edit/${id}`;
  }
  //
  function goToPrivacyPolicy() {
    return `/privacy-policy`;
  }
  function goToReturnPolicy() {
    return `/return-and-refund-policy`;
  }
  function goToTermsConditions() {
    return `/terms-conditions`;
  }

  return {
    goToHome,
    goToFeatured,
    goToProduct,
    goToCategory,
    goToShop,
    goToLogin,
    goToRegister,
    goToVerification,
    goToForgotPassword,
    goToCheckout,
    goToThankYou,

    //product
    goToBestSeller,

    //profile
    goToAccount,
    goToProfile,
    goToSavedCards,
    goToAddressBook,
    goToOrderHistory,
    goToAddAddressBook,
    goToEditAddressBook,

    //bzvz
    goToPrivacyPolicy,
    goToReturnPolicy,
    goToTermsConditions,
  };
}
