import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";
import Subscription from "./Subscription";

const Container = styled.div`
  width: 100%;
  padding: 0;
  background-color: var(--bg-200);
  padding: 40px 0 20px 0;
  margin-top: 40px;
  @media (min-width: 768px) {
    min-height: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: var(--text-100);

  @media (min-width: 768px) {
    max-width: var(--max-width-container);
    align-items: flex-start;
  }
`;
const GridContainer = styled.div`
  //grid-template-rows: repeat(4, 1fr);

  width: 90%;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    height: 100%;
    width: 100%;
    gap: 4px;
  }
`;
const GridItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  &:nth-child(2) {
    @media (min-width: 768px) {
      grid-column: span 2; /* Drugi element zauzima dva stuba */
    }
  }
`;

const List = styled.div`
  list-style: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  display: flex;
  gap: 14px;

  padding: var(--spacing-md) 0;
  flex-wrap: wrap;

  border-bottom: 1px solid #69696953;
  @media (min-width: 768px) {
    //display: block;
    gap: 4px;
    padding: 0;
    border: none;
    flex-direction: column;
    max-height: 200px; // inace je max 240px gore u Container imas
  }
`;

const ListItem = styled.div`
  font-size: var(--font-size-base);
  cursor: pointer;
  font-weight: 500;
  padding-bottom: var(--spacing-md);
  text-transform: capitalize;
`;

const LogoDiv = styled.div`
  font-size: var(--font-size-xlarge);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.438px;
  display: flex;
  gap: var(--spacing-xs);

  align-items: flex-start;
  cursor: pointer;
  color: var(--text-100);
  margin-bottom: 14px;
`;

const ContainerToolbar = styled.div`
  background-color: var(--text-color);
  width: 100%;
  background-color: #34393e;
  background-color: var(--bg-300);

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const WrapperToolbar = styled.div`
  width: 90%;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-direction: column;

  padding: 10px;
  gap: var(--spacing-md);
  @media (min-width: 768px) {
    flex-direction: row;
    max-width: var(--max-width-container);
    gap: 0;
  }
`;

const Text = styled.p`
  padding: 10px;
  margin: 0;
  font-size: var(--font-size-small);
  font-weight: bold;
  color: var(--text-100);
  @media (min-width: 768px) {
    display: inline-block;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  justify-content: flex-start;
  cursor: pointer;
`;
const CategoriesTitle = styled.h3`
  padding: 14px 0 10px 0;
  font-size: var(--font-size-large);
  color: var(--text-100);
  @media (min-width: 768px) {
    padding: 0;
    padding-bottom: var(--spacing-md);
  }
`;
const Wraning = styled.p`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: var(--p-color);
  font-family: "Satoshi";
  font-size: var(--font-size-base);
  font-weight: 700;
  gap: 4px;
  width: 90%;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;
const LinkA = styled.a`
  color: inherit;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Footer = () => {
  const { t } = useTranslation();
  const { categories } = useContext(ProductContext);
  const navigate = useNavigate();
  const {
    goToCategory,
    goToSupport,
    goToPrivacyPolicy,
    goToReturnPolicy,
    goToTermsConditions,
  } = useNavigation();
  return (
    <div>
      <Container>
        <Subscription />
        <Wrapper>
          <GridContainer>
            <GridItems>
              <CategoriesTitle>Help & Support </CategoriesTitle>
              <List>
                <ListItem onClick={() => navigate(goToSupport())}>
                  {t("FOOTER.FAQS")}
                </ListItem>
                <ListItem onClick={() => navigate(goToPrivacyPolicy())}>
                  {t("FOOTER.PRIVACY_POLICY")}
                </ListItem>
                <ListItem onClick={() => navigate(goToReturnPolicy())}>
                  {t("FOOTER.RETURN_POLICY")}
                </ListItem>
                <ListItem onClick={() => navigate(goToTermsConditions())}>
                  {t("FOOTER.TERMS_CONDITIONS")}
                </ListItem>
              </List>
            </GridItems>

            <GridItems>
              <CategoriesTitle>{t("FOOTER.CATEGORIES")}</CategoriesTitle>
              {categories && categories.length > 0 && (
                <List>
                  {categories.map((category, index) => (
                    <ListItem
                      key={index}
                      onClick={() => navigate(goToCategory(category.name))}
                    >
                      {category.name}
                    </ListItem>
                  ))}
                </List>
              )}
            </GridItems>

            <GridItems>
              <CategoriesTitle>Get in touch</CategoriesTitle>
              <List style={{ display: "block" }}>
                <ListItem style={{ textTransform: "lowercase" }}>
                  <LinkA href="mailto:info@snusco.com">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <rect
                        x="3"
                        y="5"
                        width="18"
                        height="14"
                        rx="2"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                    info@snusco.com
                  </LinkA>
                  <p>Response in 24 - 48 hours</p>
                </ListItem>
                <ListItem>
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=Michael-Reš-Gasse+2/46+Wien"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 9.5C5 6.09371 8.00993 3 12 3C15.9901 3 19 6.09371 19 9.5C19 11.6449 17.6877 14.0406 15.9606 16.2611C14.5957 18.016 13.0773 19.5329 12 20.5944C10.9227 19.5329 9.40427 18.016 8.03935 16.2611C6.31229 14.0406 5 11.6449 5 9.5ZM12 1C6.99007 1 3 4.90629 3 9.5C3 12.3551 4.68771 15.2094 6.46065 17.4889C7.99487 19.4615 9.7194 21.1574 10.7973 22.2173C10.9831 22.4001 11.1498 22.564 11.2929 22.7071C11.4804 22.8946 11.7348 23 12 23C12.2652 23 12.5196 22.8946 12.7071 22.7071C12.8502 22.564 13.0169 22.4001 13.2027 22.2174L13.2028 22.2173C14.2806 21.1573 16.0051 19.4615 17.5394 17.4889C19.3123 15.2094 21 12.3551 21 9.5C21 4.90629 17.0099 1 12 1ZM12 12.5C13.3807 12.5 14.5 11.3807 14.5 10C14.5 8.61929 13.3807 7.5 12 7.5C10.6193 7.5 9.5 8.61929 9.5 10C9.5 11.3807 10.6193 12.5 12 12.5Z"
                        fill="#000000"
                      />
                    </svg>
                    Michael-Reš-Gasse 2/46 Wien
                  </a>
                </ListItem>
                <ListItem>
                  <LinkA href="tel:+436677628134">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    +43 667 3329603
                  </LinkA>
                  <p>
                    Mon–Tues 10am - 8pm EDT Wed-Sat 10am - 1pm & 2pm- 6pm EDT
                    Sunday - Closed
                  </p>
                </ListItem>
              </List>
            </GridItems>
          </GridContainer>
          <Wraning>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1208_12314)">
                <path
                  d="M12 2C17.523 2 22 6.477 22 12C22.0021 14.6255 20.9715 17.1464 19.1309 19.0186C17.2902 20.8908 14.7872 21.9641 12.162 22.0066C9.53689 22.0491 7.00034 21.0576 5.1 19.246C3.19966 17.4344 2.08799 14.9482 2.005 12.324L2 12L2.004 11.72C2.152 6.327 6.57 2 12 2ZM12.01 15L11.883 15.007C11.64 15.0359 11.4159 15.153 11.2534 15.336C11.0909 15.519 11.0011 15.7552 11.0011 16C11.0011 16.2448 11.0909 16.481 11.2534 16.664C11.4159 16.847 11.64 16.9641 11.883 16.993L12 17L12.127 16.993C12.37 16.9641 12.5941 16.847 12.7566 16.664C12.9191 16.481 13.0089 16.2448 13.0089 16C13.0089 15.7552 12.9191 15.519 12.7566 15.336C12.5941 15.153 12.37 15.0359 12.127 15.007L12.01 15ZM12 7C11.7551 7.00003 11.5187 7.08996 11.3356 7.25272C11.1526 7.41547 11.0357 7.63975 11.007 7.883L11 8V12L11.007 12.117C11.0359 12.36 11.153 12.5841 11.336 12.7466C11.519 12.9091 11.7552 12.9989 12 12.9989C12.2448 12.9989 12.481 12.9091 12.664 12.7466C12.847 12.5841 12.9641 12.36 12.993 12.117L13 12V8L12.993 7.883C12.9643 7.63975 12.8474 7.41547 12.6644 7.25272C12.4813 7.08996 12.2449 7.00003 12 7Z"
                  fill="#7B7E82"
                />
              </g>
              <defs>
                <clipPath id="clip0_1208_12314">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {t("FOOTER.WARNING")}
          </Wraning>
          <div>
            <div
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id="56278e9abfbbba0bdcd568bc"
              data-businessunit-id="66d39814fee7b0c8732e417b"
              data-style-height="52px"
              data-style-width="100%"
            >
              <a
                href="https://www.trustpilot.com/review/snusco.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>
          </div>
        </Wrapper>
      </Container>
      <ContainerToolbar>
        <WrapperToolbar>
          <span>
            <Text>{t("FOOTER.COPYRIGHT")}</Text>
          </span>
          <IconContainer>
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3 9.6C17.6314 9.15817 17.5418 8.53137 17.1 8.2C16.6582 7.86863 16.0314 7.95817 15.7 8.4L13.3918 11.4776L11.2071 9.29289C11.0021 9.08791 10.7183 8.98197 10.4291 9.00252C10.1399 9.02307 9.87393 9.16809 9.7 9.4L6.7 13.4C6.36863 13.8418 6.45817 14.4686 6.9 14.8C7.34183 15.1314 7.96863 15.0418 8.3 14.6L10.6082 11.5224L12.7929 13.7071C12.9979 13.9121 13.2817 14.018 13.5709 13.9975C13.8601 13.9769 14.1261 13.8319 14.3 13.6L17.3 9.6Z"
                fill="var(--text-100)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                fill="var(--text-100)"
              />
            </svg>
            <a
              href="https://www.instagram.com/snuscogmbh/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                  fill="var(--text-100)"
                />
                <path
                  d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"
                  fill="var(--text-100)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"
                  fill="var(--text-100)"
                />
              </svg>
            </a>
            <a
              href="https://wa.me/message/PFGFC6WZV66NC1 "
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.014 8.00613C6.12827 7.1024 7.30277 5.87414 8.23488 6.01043L8.23339 6.00894C9.14051 6.18132 9.85859 7.74261 10.2635 8.44465C10.5504 8.95402 10.3641 9.4701 10.0965 9.68787C9.7355 9.97883 9.17099 10.3803 9.28943 10.7834C9.5 11.5 12 14 13.2296 14.7107C13.695 14.9797 14.0325 14.2702 14.3207 13.9067C14.5301 13.6271 15.0466 13.46 15.5548 13.736C16.3138 14.178 17.0288 14.6917 17.69 15.27C18.0202 15.546 18.0977 15.9539 17.8689 16.385C17.4659 17.1443 16.3003 18.1456 15.4542 17.9421C13.9764 17.5868 8 15.27 6.08033 8.55801C5.97237 8.24048 5.99955 8.12044 6.014 8.00613Z"
                  fill="var(--text-100)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 23C10.7764 23 10.0994 22.8687 9 22.5L6.89443 23.5528C5.56462 24.2177 4 23.2507 4 21.7639V19.5C1.84655 17.492 1 15.1767 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM6 18.6303L5.36395 18.0372C3.69087 16.4772 3 14.7331 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C11.0143 21 10.552 20.911 9.63595 20.6038L8.84847 20.3397L6 21.7639V18.6303Z"
                  fill="var(--text-100)"
                />
              </svg>
            </a>
          </IconContainer>
        </WrapperToolbar>
      </ContainerToolbar>
    </div>
  );
};

export default Footer;
