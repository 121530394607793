import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { paymentMethods } from "../../utils/global_const";
import CreditCardsCheckout from "../../components/stripe/CreditCardsCheckout";
import { useEffect, useState } from "react";
import CreditCardButton from "../stripe/CreditCardButton";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(
  "pk_test_51QI78aHyD45BjVAFFnBI17A25VDrrBQ94NJ23DJarIxnG5YPjwJGKiG2iKKIymERIFvJVxMUCjn3giAUHT7uIbtf00DsMkBivd"
);
const appearance = {
  theme: "stripe",
};

const PaymentSection = styled.div`
  margin-bottom: 32px;
  width: 100%;
  padding: 12px 0;
  border: 1px solid var(--bg-200);
  background-color: var(--bg-200);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaymentWrapper = styled.div`
  width: 96%;
`;
const SectionTitle = styled.h3`
  font-weight: 600;
  margin-bottom: 16px;
`;

const RadioOption = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 16px;
  cursor: pointer;

  input {
    margin-right: 12px;
    accent-color: #0070f3;
  }

  &:hover {
    color: #0070f3;
  }
`;

const Step3 = ({ setPaymentMethod, address, onSubmit }) => {
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethodLocal, setPaymentMethodLocal] = useState("cod");

  useEffect(() => {
    setPaymentMethod(paymentMethodLocal);
  }, [paymentMethodLocal, setPaymentMethod]);

  const selectedMethod = paymentMethods.find(
    (method) => method.code === paymentMethodLocal
  );

  return (
    <>
      <PaymentSection>
        <PaymentWrapper>
          <SectionTitle>{t("PAYMENT_METHOD.SELECT")}</SectionTitle>
          {paymentMethods.map((method) => (
            <RadioOption key={method.code}>
              <input
                type="radio"
                value={method.code}
                checked={paymentMethodLocal === method.code}
                onChange={() => setPaymentMethodLocal(method.code)}
                disabled={!method.available}
              />
              {t(`PAYMENT_METHOD.${method.code.toUpperCase()}`)}{" "}
              {!method.available && ` ${t("PAYMENT_METHOD.UNAVAILABLE")}`}
            </RadioOption>
          ))}
        </PaymentWrapper>
      </PaymentSection>

      {selectedMethod?.code === "card" && selectedMethod.available && (
        <>
          <CreditCardsCheckout
            address={address}
            setClientSecret={setClientSecret}
          />
          <CreditCardButton
            clientSecret={clientSecret}
            handleOrderSubmission={onSubmit}
          />
        </>
      )}
    </>
  );
};

export default Step3;
