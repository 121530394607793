import { useRef, useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../../components/section/BreadCrumbs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Container = styled.main``;

const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
    //width: 800px;
  }
`;

const Text = styled.p`
  color: var(--text-200);
  font-size: 18px;
  line-height: 1.6;
  font-weight: 600;
`;

const Title = styled.h3`
  font-size: var(--font-size-xlarge);
  margin: 0;
  margin-top: var(--spacing-lg);
  font-weight: 600;
  @media (min-width: 1025px) {
    font-size: var(--font-size-xxlarge);
  }
`;
const TitleSmall = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  color: var(--text-100);
`;

const LinkList = styled.ol`
  margin-top: var(--spacing-md);
  margin-left: 24px;
`;

const LinkItem = styled.li`
  margin-bottom: 12px;
  color: var(--text-200);
  font-size: 14px;
  cursor: pointer;

  a {
    color: var(--primary-color, #0070f3);
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BreadcrumbsContainer = styled.div`
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
  width: 94%;
`;

const StyledList = styled.ul`
  padding-left: 20px;
  margin-top: 16px;
  color: var(--text-200);
  font-size: 16px;
  font-weight: 100;
`;

const StyledListItem = styled.li`
  margin-bottom: 12px;
`;

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);
  const section10Ref = useRef(null);
  const section11Ref = useRef(null);
  const section12Ref = useRef(null);

  const [breadcrumbItems] = useState([
    { name: "SnusCo", url: "/" },
    { name: "Privacy Policy", url: `/${i18n.language}/privacy-policy` },
  ]);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <SupportContainer>
        <MaxWidthWrapper>
          <BreadcrumbsContainer className="header-top-padding">
            <Breadcrumbs breadcrumbs={breadcrumbItems} />
          </BreadcrumbsContainer>

          <Title>Privacy Notice</Title>
          <Text>
            SnusCo GmbH ("we", "us", or "our") respects your privacy and is
            committed to protecting it through this Privacy Policy.
          </Text>
          <Text>
            This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you visit our website. Please read
            this privacy notice carefully.
          </Text>
          <Text>
            If you have questions or concerns, feel free to contact us. This
            policy may be updated from time to time, and we encourage you to
            check back regularly.
          </Text>

          {/* Klikabilna numerisana lista */}
          <LinkList>
            <LinkItem>
              <a onClick={() => scrollToSection(section1Ref)}>
                What personal information do we collect?
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section2Ref)}>
                From which sources do we collect information about you?
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section3Ref)}>
                Why do we process personal information?
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section4Ref)}>
                What Privacy Rights and Choices May Be Available?
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section5Ref)}>
                How Long do We Retain Personal Information?
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section6Ref)}>
                Supplemental Privacy Notice for Residents of the European Union
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section7Ref)}>Do Not Track</a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section8Ref)}>
                International Data Transfers
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section9Ref)}>
                {" "}
                Links to Other Sites
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section10Ref)}>Contact Us</a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section11Ref)}>
                Changes to this Privacy Notice
              </a>
            </LinkItem>
            <LinkItem>
              <a onClick={() => scrollToSection(section12Ref)}>
                Applicable Law & Jurisdiction
              </a>
            </LinkItem>
          </LinkList>
        </MaxWidthWrapper>
      </SupportContainer>

      {/* Sekcija 1 */}
      <SupportContainer ref={section1Ref}>
        <MaxWidthWrapper>
          <Title>1. What personal information do we collect?</Title>
          <Text>
            SnusCo may collect information that is personally identifiable to
            you. This information may include:
          </Text>
          <StyledList>
            <StyledListItem>
              Contact details such as name, address, phone number and e-mail
              address.
            </StyledListItem>
            <StyledListItem>Age, birth date, and gender.</StyledListItem>
            <StyledListItem>
              Order information, for example order number, date, products,
              quantity, price, and shipping and payment methods, including
              purchasing history.
            </StyledListItem>
            <StyledListItem>
              Payment details, such as payment method, transaction time, paid
              amount and IP address. Any payments made are processed by
              third-party payment processors. We do not directly collect or
              store any payment card information entered through our Site, but
              we may receive information associated with your payment card
              information (e.g., your billing details).
            </StyledListItem>
            <StyledListItem>
              Account information, such as account number, username, password
              and date of registration.
            </StyledListItem>
            <StyledListItem>
              Correspondence and other information you provide to us related to
              support matters such as notes and emails from contacts with
              Customer Care.
            </StyledListItem>
            <StyledListItem>
              Feedback and survey responses, which may include personal
              information relating to your lifestyle and usage of our products.
            </StyledListItem>
            <StyledListItem>
              Information we collect automatically about your use of our Site
              and interaction with our marketing communication, for example IP
              address, geolocation data, device information (technical details
              like browser, OS, screen size, etc.), browsing and searches.
            </StyledListItem>
          </StyledList>
        </MaxWidthWrapper>
      </SupportContainer>

      {/* Sekcija 2 */}
      <SupportContainer ref={section2Ref}>
        <MaxWidthWrapper>
          <Title>2. Why do we process your personal information?</Title>
          <Text>
            We collect information through direct interactions with you, as well
            as by automated technologies such as cookies and web beacons. For
            example, when you submit an order to us, create an account, complete
            one of our surveys or contact us via the Site’s webform, you are
            asked to provide us with various personal information. For more
            information about how we use automated information collection tools,
            please see below. We may also combine your personal information with
            information we receive from third parties such as analytics
            providers, advertising networks and data brokers or aggregators.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      {/* Sekcija 3 */}
      <SupportContainer ref={section3Ref}>
        <MaxWidthWrapper>
          <Title>3. Why do we process personal information?</Title>
          <Text>
            We collect and process personal information about you for various
            purposes, mainly the purposes described below and for any other
            purposes stated at the point of collection. Kindly note that this is
            a general description of our processing and that all processing may
            not be applicable to your personal information.
          </Text>

          <StyledList>
            <StyledListItem>
              To administrate your user account and profile.
            </StyledListItem>
            <StyledListItem>
              To manage orders and purchases, including processing your
              financial information and other payment methods for purchases.
            </StyledListItem>
            <StyledListItem>To verify your age for a purchase.</StyledListItem>
            <StyledListItem>
              To manage support matters with Customer Care or our Privacy Team,
              for example correcting incorrect information and authenticating
              users that interact with our Site and Customer Care.
            </StyledListItem>
            <StyledListItem>
              To provide personalized information, offers and recommendations
              based on what other persons with similar behaviors have been
              interested in, and which products you have purchased or shown
              interest in.
            </StyledListItem>
            <StyledListItem>
              To market, advertise, and provide other information about our
              products and services, including but not limited to newsletters.
              This may include using personal information to tailor and provide
              you with content and advertisements. Some of the ways we market to
              you include email campaigns, custom audiences advertising and
              “interest-based” or “personalized advertising,” including through
              cross-device tracking. If you have any questions about our
              marketing practices or if you would like to opt out of the use of
              your personal information for marketing purposes, you may contact
              us at any time as set forth in “Contact Us” below.
            </StyledListItem>
            <StyledListItem>
              To communicate important messages, such as material changes to our
              Terms and Conditions or Privacy Notice.
            </StyledListItem>
            <StyledListItem>
              To carry out market research, including evaluation of our customer
              database to assess which customers would be suitable participants.
            </StyledListItem>
            <StyledListItem>
              To coordinate and administer participation in events,
              competitions, and other similar activities.
            </StyledListItem>
            <StyledListItem>
              To evaluate, develop and improve the Site as well as our services,
              products, and systems, for example technical troubleshooting and
              backup storage.
            </StyledListItem>
            <StyledListItem>
              Detecting security incidents, protecting against malicious,
              deceptive, fraudulent or illegal activity, and prosecuting those
              responsible for that activity.
            </StyledListItem>
            <StyledListItem>
              To produce anonymous, aggregated datasets, and to conduct
              analytics on these datasets, in order to better understand our
              customers, set our business and marketing strategies and provide
              relevant data to our partners for their business use.
            </StyledListItem>
            <StyledListItem>
              Sharing personal information with third parties as needed to
              operate the Sites.
            </StyledListItem>
            <StyledListItem>
              Enforcing our agreements and policies.
            </StyledListItem>
            <StyledListItem>
              To fulfill our legal obligations, for example related to
              accounting requirements or product safety.
            </StyledListItem>
            <StyledListItem>
              To establish, exercise or defend legal claims, including to detect
              fraud or other illegal activities.
            </StyledListItem>
            <StyledListItem>
              We may use personal information for other purposes that are
              clearly disclosed to you at the time you provide personal
              information or with your consent.
            </StyledListItem>
          </StyledList>
        </MaxWidthWrapper>
      </SupportContainer>

      {/* Sekcija 4 */}
      <SupportContainer ref={section4Ref}>
        <MaxWidthWrapper>
          <Title>4. What Privacy Rights and Choices May Be Available?</Title>
          <Text>
            In accordance with applicable law you may have the following rights:
          </Text>
          <StyledList>
            <StyledListItem>
              <strong>Request to Know.</strong> You may have the right to
              request that we disclose certain information to you about our
              collection, use and disclosure of your personal information. Once
              we receive and confirm your verifiable consumer request, we will
              disclose to you any or all of the following, as requested by you:
              <StyledList>
                <StyledListItem>
                  The specific pieces of personal information we collected about
                  you;
                </StyledListItem>
                <StyledListItem>
                  The categories of personal information we collected about you;
                </StyledListItem>
                <StyledListItem>
                  The categories of sources from which we collect personal
                  information about you;
                </StyledListItem>
                <StyledListItem>
                  The categories of personal information that we have disclosed
                  about you for a business purpose;
                </StyledListItem>
                <StyledListItem>
                  The categories of third parties to whom we have disclosed your
                  personal information for a business purpose;
                </StyledListItem>
                <StyledListItem>
                  Our business or commercial purpose for collecting personal
                  information.
                </StyledListItem>
              </StyledList>
            </StyledListItem>

            <StyledListItem>
              <strong>Request to Delete.</strong> You have the right to request
              that we delete any of your personal information that we collected
              from you and retained, subject to certain exceptions. Once we
              receive and confirm your verifiable consumer request, we will
              delete (and direct our service providers to delete, as applicable)
              your personal information from our records, unless an exception
              applies.
            </StyledListItem>

            <StyledListItem>
              <strong>Request Correction</strong> of your personal information
              where it is inaccurate or incomplete. In some cases, we may
              provide self-service tools that enable you to update your personal
              information.
            </StyledListItem>

            <StyledListItem>
              <strong>Request Restriction of or Opt-out</strong> of our
              processing of your personal information where the processing
              involves: (i) processing or sharing your sensitive personal
              information for certain purposes, or (ii) profiling that produces
              legal or similarly significant effects concerning you.
            </StyledListItem>

            <StyledListItem>
              <strong>
                Request to Opt-Out of the Sale of Personal Information or
                Sharing for Cross-Contextual or Targeted Advertising.
              </strong>{" "}
              You may have the right to opt out of the sale or sharing of your
              personal information for targeted advertising. Although we do not
              "sell" personal data in the traditional sense, we may share it for
              marketing purposes. To opt out, contact us at{" "}
              <a href="mailto:info@snusco.com">info@snusco.com</a>, call +43 667
              7628134, or follow the instructions below.
            </StyledListItem>
          </StyledList>

          <Text>
            Each of these categories may have been shared with our Marketing and
            Advertising Partners for the purpose of providing targeted
            advertising and marketing.
          </Text>

          <TitleSmall>No Discrimination</TitleSmall>
          <Text>
            You have a right not to receive discriminatory treatment by us for
            the exercise of the privacy rights conferred by applicable state
            law.
          </Text>

          <TitleSmall>How to Exercise Your Privacy Rights</TitleSmall>
          <Text>
            To inquire about or exercise your privacy rights, you may submit a
            request by emailing us at{" "}
            <a href="mailto:info@snusco.com">info@snusco.com</a> or by calling
            us at <a href="tel:+436677628134">+43 667 7628134</a>. In connection
            with any personal information we may collect through cookies, for
            your deletion request to be effective, you must also delete cookies
            using each device and browser you use in connection with our
            website.
          </Text>
          <Text>
            To find out more about cookies, including how to see what cookies
            have been set and how to manage and delete them, visit{" "}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.allaboutcookies.org
            </a>
            .
          </Text>

          <TitleSmall>Verification Methods</TitleSmall>
          <Text>
            Once you submit a request, we will verify that you are the consumer
            to which the request pertains by matching the identifying
            information provided by you (e.g., name, email address,
            account-related information) to the information we maintain.
          </Text>
          <Text>
            Depending on the type of request you submit, we will attempt to
            match either two or three of the data points you provided. If we are
            unable to verify your request with the data points you provided, we
            may reach out to you for additional information to verify your
            request.
          </Text>

          <TitleSmall>Authorized Agent</TitleSmall>
          <Text>
            You can select an “authorized agent” to submit requests on your
            behalf. We will require the authorized agent to have a written
            authorization confirming that authority. We may also require you to
            verify your own identity directly with us.
          </Text>
          <Text>
            Once your authorized agent is verified, they may submit a request in
            the typical way described above.
          </Text>

          <TitleSmall>Appeal</TitleSmall>
          <Text>
            You may have the right to appeal our decision or response to your
            request. To exercise your right to appeal, you can submit an appeal
            request using the same method used to submit your original request,
            including by contacting us as set forth in the “Contact Us” section
            below.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>
      {/* Sekcija 5 */}
      <SupportContainer ref={section5Ref}>
        <MaxWidthWrapper>
          <Title>5. How Long do We Retain Personal Information?</Title>

          <Text>
            We store the personal information we collect as described in this
            Privacy Notice for as long as you use our Site, or as necessary to
            fulfill the purpose(s) for which it was collected, provide our Site
            and products, resolve disputes, establish legal defenses, conduct
            audits, pursue legitimate business purposes, enforce our agreements,
            and comply with applicable laws, or based upon other criteria,
            including, but not limited to, the sensitivity and volume of such
            data.
          </Text>

          <Text>
            Additionally, we endeavor to retain all such personal information in
            accordance with legal requirements. Please find below detailed
            information regarding our use of personal information, including the
            categories of personal information used and for how long the
            personal information is stored.
          </Text>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Th>Purpose</Th>
                  <Th>Personal Information</Th>
                  <Th>Storage Period</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>Manage your order or subscription</Td>
                  <Td>
                    Communication, Contact information, Identity information,
                    Order and delivery information
                  </Td>
                  <Td>
                    Until order is processed + 36 months, or longer if necessary
                  </Td>
                </tr>
                <tr>
                  <Td>Manage your order or subscription</Td>
                  <Td>
                    Communication, Contact information, Identity information,
                    User generated information
                  </Td>
                  <Td>
                    Active customer relationship + 6 months (max 18 months)
                  </Td>
                </tr>
                <tr>
                  <Td>Provide services on our websites</Td>
                  <Td>
                    Communication, Contact information, Identity information,
                    Order and delivery information
                  </Td>
                  <Td>
                    Until order is processed + 36 months, or longer if necessary
                  </Td>
                </tr>
                <tr>
                  <Td>Respond to questions and provide customer service</Td>
                  <Td>
                    Communication, Contact information, Identity information,
                    Order and delivery information
                  </Td>
                  <Td>
                    As long as active relationship, max 18 months from
                    completion
                  </Td>
                </tr>
                <tr>
                  <Td>Communicate and send offers</Td>
                  <Td>
                    Communication, Contact info, Identity info, Profile info,
                    Technical info, User generated info
                  </Td>
                  <Td>
                    Active relationship + 12 months (max 24 months); order info
                    up to 27 months
                  </Td>
                </tr>
                <tr>
                  <Td>Customer insight analysis</Td>
                  <Td>
                    Communication, Contact info, Identity info, Profile info,
                    Technical info, User generated info
                  </Td>
                  <Td>
                    27 months; aggregated non-personal data retained until
                    deleted
                  </Td>
                </tr>
                <tr>
                  <Td>Newsletter</Td>
                  <Td>Contact information, Identity information</Td>
                  <Td>
                    Active customer relationship + 6 months (max 18 months)
                  </Td>
                </tr>
                <tr>
                  <Td>Business analysis</Td>
                  <Td>
                    Identity info, Order info, Profile info, User generated info
                  </Td>
                  <Td>
                    27 months; aggregated non-personal data retained until
                    deleted
                  </Td>
                </tr>
                <tr>
                  <Td>Contests, events, activities</Td>
                  <Td>Communication, Contact info, Identity info</Td>
                  <Td>During activity + 26 months, then anonymized</Td>
                </tr>
                <tr>
                  <Td>Market surveys</Td>
                  <Td>Communication, Contact info, Identity info</Td>
                  <Td>During survey + 3 months, then anonymized</Td>
                </tr>
                <tr>
                  <Td>Business development</Td>
                  <Td>
                    Identity info, Order info, Profile info, User generated info
                  </Td>
                  <Td>
                    27 months; aggregated non-personal data retained until
                    deleted
                  </Td>
                </tr>
                <tr>
                  <Td>Website & digital channel usage</Td>
                  <Td>
                    Identity info, Profile info, Technical info, User generated
                    info
                  </Td>
                  <Td>
                    See cookie policy; aggregated data retained until deleted
                  </Td>
                </tr>
                <tr>
                  <Td>Website functionality</Td>
                  <Td>Technical information</Td>
                  <Td>
                    See cookie policy; aggregated data retained until deleted
                  </Td>
                </tr>
                <tr>
                  <Td>Partner relationship management</Td>
                  <Td>Identity info, Order info, Technical info</Td>
                  <Td>As long as active + 10 years</Td>
                </tr>
                <tr>
                  <Td>Technical functionality & security</Td>
                  <Td>Relevant personal info</Td>
                  <Td>Logs & backups: 13 months; other info as per purpose</Td>
                </tr>
                <tr>
                  <Td>Legal claims management</Td>
                  <Td>Only necessary personal info per case</Td>
                  <Td>As long as needed to manage legal claim</Td>
                </tr>
                <tr>
                  <Td>Legal obligations</Td>
                  <Td>Only necessary personal info per obligation</Td>
                  <Td>As required + 10 years (e.g. accounting: 7 years)</Td>
                </tr>
              </tbody>
            </Table>
          </TableWrapper>
        </MaxWidthWrapper>
      </SupportContainer>

      {/* Sekcija 6 */}
      <SupportContainer ref={section6Ref}>
        <MaxWidthWrapper>
          <Title>
            6. Supplemental Privacy Notice for Residents of the European Union
          </Title>

          <Text>
            This supplemental privacy notice is intended for residents of the
            European Union under the General Data Protection Regulation (GDPR).
            The following table provides an overview of personal data categories
            we process, examples, and categories of recipients we may disclose
            data to.
          </Text>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Th>Category of Personal Information</Th>
                  <Th>Examples</Th>
                  <Th>Disclosed To</Th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Td>Identifiers</Td>
                  <Td>Name, postal address, IP address, email, account name</Td>
                  <Td>
                    Service providers, business partners, affiliates, ad
                    networks, analytics providers, authorities
                  </Td>
                </tr>
                <tr>
                  <Td>Personal data categories</Td>
                  <Td>
                    Name, address, phone number, bank account, card numbers
                  </Td>
                  <Td>Same as above</Td>
                </tr>
                <tr>
                  <Td>Commercial data</Td>
                  <Td>
                    Purchase history, product interest, consumption behavior
                  </Td>
                  <Td>Same as above</Td>
                </tr>
                <tr>
                  <Td>Electronic activity</Td>
                  <Td>Browsing/search history, site/app interaction</Td>
                  <Td>Same as above</Td>
                </tr>
                <tr>
                  <Td>Geolocation data</Td>
                  <Td>Physical location, movements</Td>
                  <Td>Same as above</Td>
                </tr>
                <tr>
                  <Td>Inferred profiles</Td>
                  <Td>Preferences, attitudes, behavioral profiles</Td>
                  <Td>Same as above</Td>
                </tr>
              </tbody>
            </Table>
          </TableWrapper>

          <TitleSmall>Sources of Personal Information</TitleSmall>
          <Text>
            We collect your personal information through direct interactions
            (e.g., account creation, orders, support), automated technologies
            (e.g., cookies), and from third parties (e.g., analytics providers,
            advertising partners).
          </Text>

          <TitleSmall>Purposes for Collection</TitleSmall>
          <Text>
            We process your data for legitimate interests, legal obligations,
            contract fulfillment, and consent-based interactions. See Section 3
            “Why do we process personal information” for full details.
          </Text>

          <TitleSmall>Financial Incentives</TitleSmall>
          <Text>
            When you register or subscribe, we may offer promotions or discounts
            in exchange for information such as your email, birthday, or
            preferences. These offers are voluntary, and the value may vary
            depending on the promotion and your activity.
          </Text>

          <TitleSmall>Use of Sensitive Personal Information</TitleSmall>
          <Text>
            If we collect sensitive information (e.g., payment details), it is
            only used for the purpose of providing our services securely.
          </Text>

          <TitleSmall>Accessibility / Alternate Formats</TitleSmall>
          <Text>
            If you have a disability and need this policy in an alternate
            format, please contact us at{" "}
            <Link href="mailto:info@snusco.com">info@snusco.com</Link> and we
            will assist you accordingly.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      <SupportContainer ref={section7Ref}>
        <MaxWidthWrapper>
          <Title>7. Do Not Track</Title>
          <Text>
            Our Site does not currently take any action when it receives a Do
            Not Track request.
          </Text>
          <Text>
            Do Not Track is a privacy preference that you can set in your web
            browser to indicate that you do not want certain information about
            your webpage visits collected across websites when you have not
            interacted with that service on the page.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      <SupportContainer ref={section8Ref}>
        <MaxWidthWrapper>
          <Title>8. International Data Transfers</Title>
          <Text>
            The Sites are only directed at, and intended for use by, residents
            of the European Union and relevant jurisdictions. All information
            processed by us may be transferred, processed, and stored anywhere
            in the world, including outside the EU/EEA, in compliance with
            applicable data protection regulations. We endeavor to safeguard
            your information in accordance with GDPR and other relevant laws.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      <SupportContainer ref={section9Ref}>
        <MaxWidthWrapper>
          <Title>9. Links to Other Sites</Title>
          <Text>
            Our Site may contain links to third party websites. Any access to
            and use of such websites is not governed by this Privacy Notice, but
            by the privacy notices or policies of those third parties. We are
            not responsible for their data practices and encourage you to review
            their privacy policies before providing any personal data.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      <SupportContainer ref={section10Ref}>
        <MaxWidthWrapper>
          <Title>10. Contact Us</Title>
          <Text>
            If you have any questions regarding this Privacy Notice, feel free
            to contact us via email at{" "}
            <a href="mailto:info@snusco.com">info@snusco.com</a> or by writing
            to us at:
          </Text>
          <Text>
            <strong>SnusCo GmbH</strong>
            <br />
            Michael-Ress-Gasse 2<br />
            1210 Wien, Austria
          </Text>
          <Text>
            You may also call our Customer Service at +43 1 234 5678 or reach us
            via our contact form on the website.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      <SupportContainer ref={section11Ref}>
        <MaxWidthWrapper>
          <Title>11. Changes to this Privacy Notice</Title>
          <Text>
            We reserve the right to update or change this Privacy Notice at any
            time. All changes will be posted on this page, and where
            appropriate, we will notify you by other means.
          </Text>
          <Text>
            We encourage you to review this Privacy Notice periodically for any
            updates. The latest update was made on:{" "}
            <strong>April 6, 2025</strong>.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>

      <SupportContainer ref={section12Ref}>
        <MaxWidthWrapper>
          <Title>12. Applicable Law & Jurisdiction</Title>
          <Text>
            This Privacy Notice is governed by the laws of Austria and the
            European Union. Any disputes arising in relation to this policy
            shall fall under the jurisdiction of Austrian courts unless
            otherwise required by applicable EU regulations.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>
    </Container>
  );
};

export default PrivacyPolicy;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f6f6f6;
  font-weight: 600;
  border: 1px solid #e0e0e0;
`;

const Td = styled.td`
  padding: 12px;
  vertical-align: top;
  border: 1px solid #e0e0e0;
  color: var(--text-200);
`;
