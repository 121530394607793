import { useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../../components/section/BreadCrumbs";
import { useTranslation } from "react-i18next";

const Container = styled.main``;

const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
    //width: 800px;
  }
`;

const Text = styled.p`
  color: var(--text-200);
  font-size: 18px;
  line-height: 1.6;
  font-weight: 600;
`;

const Title = styled.h3`
  font-size: var(--font-size-xlarge);
  margin: 0;
  margin-top: var(--spacing-lg);
  font-weight: 600;
  @media (min-width: 1025px) {
    font-size: var(--font-size-xxlarge);
  }
`;
const TitleSmall = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  color: var(--text-100);
`;

const LinkList = styled.ol`
  margin-top: var(--spacing-md);
  margin-left: 24px;
`;

const LinkItem = styled.li`
  margin-bottom: 12px;
  color: var(--text-200);
  font-size: 14px;
  cursor: pointer;

  a {
    color: var(--primary-color, #0070f3);
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BreadcrumbsContainer = styled.div`
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
  width: 94%;
`;

const StyledList = styled.ul`
  padding-left: 20px;
  margin-top: 16px;
  color: var(--text-200);
  font-size: 16px;
  font-weight: 100;
`;

const StyledListItem = styled.li`
  margin-bottom: 12px;
`;

const TermsConditionsMain = () => {
  const { t, i18n } = useTranslation();

  const [breadcrumbItems] = useState([
    { name: "SnusCo", url: "/" },
    {
      name: "Terms Conditions",
      url: `/${i18n.language}/terms-conditions`,
    },
  ]);

  return (
    <Container>
      <SupportContainer>
        <MaxWidthWrapper>
          <BreadcrumbsContainer className="header-top-padding">
            <Breadcrumbs breadcrumbs={breadcrumbItems} />
          </BreadcrumbsContainer>

          <Title>Terms & Conditions</Title>
          <TitleSmall>GENERAL</TitleSmall>
          <Text>
            This website, www.snusco.at (the “Website”), is owned and operated
            by SnusCo GmbH, registered in Austria under company VAT number
            [ATU81137235] (“SnusCo”, “we”, “us”, “our”). The Website is
            available for purchases by consumers who are 18 years of age or
            older and reside within the European Union or other permitted
            countries. Please read these Terms of Sale (“Terms”) carefully, as
            they govern your purchase of products from SnusCo and contain
            important information about limitations, legal rights, and
            responsibilities. By placing an order through the Website, you
            confirm that you have read, understood, and agreed to be bound by
            these Terms. You also acknowledge that you have read and understood
            our Privacy Policy and agree to our processing of your personal data
            in accordance with applicable laws. SnusCo reserves the right to
            update or modify these Terms at any time. Any changes will be
            published on the Website and will apply to future orders placed
            after such publication.
          </Text>
          <TitleSmall>ORDERING PRODUCTS AT THE WEBSITE</TitleSmall>
          <Text>
            SnusCo offers nicotine pouch products as available on the Website
            from time to time. Product images on the Website are for
            illustrative purposes only and actual products may vary slightly
            from those images.
            <br />
            <br />
            By placing an order with SnusCo, you confirm that you are a consumer
            aged 18 years or older and have a valid delivery address within the
            European Union or other eligible countries.
            <br />
            <br />
            SnusCo reserves the right to impose quantity limits per customer and
            per time period, as specified on the Website, and may refuse or
            revoke orders in case of such limitations, suspected misuse, or at
            our sole discretion. A binding purchase agreement is concluded once
            we accept your payment, confirm your order via email, and dispatch
            your products.
          </Text>

          <TitleSmall>
            AGE VERIFICATION AND PREVENTION OF YOUTH ACCESS
          </TitleSmall>
          <Text>
            To ensure compliance with legal requirements, we apply a strict age
            verification policy. Only individuals who are 18 years or older may
            purchase products from SnusCo.
            <br />
            <br />
            We may require you to verify your age using third-party age
            verification providers or by submitting valid government-issued
            identification. SnusCo reserves the right to cancel any order if age
            verification fails or if sufficient identification is not provided.{" "}
            <br />
            <br />
            Any attempts to falsify identity or place orders on behalf of others
            without their consent will be reported to the relevant authorities.
          </Text>
          <TitleSmall>PRODUCT INFORMATION</TitleSmall>
          <Text>
            SnusCo strives to ensure that all information on the Website is
            accurate. However, we cannot guarantee complete accuracy and reserve
            the right to correct errors related to product descriptions,
            pricing, availability, or images at any time. Product images are
            illustrative and may not represent exact packaging or product
            appearance.
          </Text>
          <TitleSmall>PRICE AND PAYMENT</TitleSmall>
          <Text>
            All prices on the Website are displayed in EUR (€) and include
            applicable Austrian VAT unless stated otherwise. Additional shipping
            costs may apply and are shown during checkout. Promotions or
            discount codes may be applied as available.
            <br />
            <br />
            Payment methods include debit and credit cards, as well as other
            options provided by our payment partners (e.g., Stripe, Klarna). By
            using a payment method, you confirm that you are authorized to use
            that method. If a payment fails or is declined, the order may be
            cancelled.
          </Text>
          <TitleSmall>SHIPPING</TitleSmall>
          <Text>
            SnusCo delivers to Austria and select European countries. Shipping
            options and prices are displayed at checkout. Once your order is
            dispatched, you will receive a shipping confirmation email.
            <br />
            <br />
            Please ensure that someone is available to receive the delivery. In
            case of a failed delivery or unredeemed package, it may be returned
            to us or discarded, and you may be liable for additional costs.
          </Text>

          <TitleSmall>RETURNS</TitleSmall>
          <Text>
            Due to the perishable nature of our products, returns are generally
            not accepted. If you experience an issue with your order (e.g.
            product defect or delivery error), please contact our customer
            support within 10 days of receipt.
            <br />
            <br />
            Depending on the issue, we may request photographic evidence. You
            must retain the original packaging and order confirmation for any
            claims. If applicable, we will offer a replacement, refund, or
            discount. SnusCo complies with applicable EU consumer rights
            regulations.
            <br />
            <br />
            We are not liable for delays or damage caused during shipping, but
            we will assist you in submitting a claim to the shipping provider.
          </Text>
          <TitleSmall>EVENTS OUTSIDE OUR CONTROL</TitleSmall>
          <Text>
            SnusCo is not liable for delays or failure to fulfill our
            obligations due to Force Majeure events such as legal changes,
            government actions, natural disasters, epidemics, war, strikes, or
            other extraordinary events. If such an event lasts longer than two
            (2) months, either party may cancel the purchase agreement with
            immediate effect.
          </Text>
          <TitleSmall>PRODUCT REVIEWS</TitleSmall>
          <Text>
            By submitting a review on our Website, you grant SnusCo the right to
            publish, edit, or remove your review at our discretion on any media
            or platform owned by SnusCo.
          </Text>
          <TitleSmall>MISCELLANEOUS</TitleSmall>
          <Text>
            For questions about these Terms, returns, complaints, or delivery
            issues, please contact our customer support team via the contact
            form on the Website or email us at 📧 info@snusco.com <br />
            <br /> Please include your order number when contacting us.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>
    </Container>
  );
};

export default TermsConditionsMain;
const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 24px;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f6f6f6;
  font-weight: 600;
  border: 1px solid #e0e0e0;
`;

const Td = styled.td`
  padding: 12px;
  vertical-align: top;
  border: 1px solid #e0e0e0;
  color: var(--text-200);
`;
