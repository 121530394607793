import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { DEFAULT_LANGUAGE } from "../../utils/global_const";
import Header from "../../layouts/header/Header";
import Footer from "../../layouts/footer/Footer";
import PrivacyPolicyMain from "../../layouts/main/legal/PrivacyPolicyMain";
const Container = styled.div`
  background-color: var(--background-color);
  color: var(--text-color);
`;
const SEOConfig = {
  en: {
    lang: "en",
    title: "",
    description: "",
    og: {
      title: "",
      description: "",
    },
  },
  de: {
    lang: "de",
    title: "",
    description: "",
    og: {
      title: "",
      description: "",
    },
  },
};

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const [seo, setSeo] = useState(SEOConfig[DEFAULT_LANGUAGE]);

  useEffect(() => {
    setSeo(SEOConfig[i18n.language] || SEOConfig[DEFAULT_LANGUAGE]);
  }, [i18n.language]);
  return (
    <Container>
      <Helmet>
        <html lang={seo.lang} />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.snus-oesterreich.at/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://www.snus-oesterreich.at/assets/og-image.jpg"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="Snus Österreich" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Header />
      <PrivacyPolicyMain />
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
