import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DEFAULT_CURRENCY,
  getTransportPrice,
  paymentMethods,
} from "../../utils/global_const";
import InvoiceProduct from "./InvoiceProduct";
import styled from "styled-components";
import { ProductContext } from "../../context/ProductContext";
import { AuthUserContext } from "../../context/AuthUserContext";
import { jwtDecode } from "jwt-decode";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../utils/navigation";
import { useTranslation } from "react-i18next";
import CreditCardButton from "../stripe/CreditCardButton";

const Container = styled.div`
  border: 1px solid var(--bg-300);
  width: 100%;
  border-radius: 8px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Header = styled.h2`
  background-color: var(--accent-100);
  color: var(--bg-100);
  padding: var(--spacing-md) 2%;
  text-transform: uppercase;
  margin-bottom: 8px;
  width: 96%;
  border-radius: 8px 8px 0 0;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const P = styled.p`
  padding: 8px 0;
  margin: 0;
`;

const Submit = styled.button`
  width: 100%;
  margin: 14px 0;

  opacity: ${(props) =>
    props.disabled ? 0.5 : 1}; /* Stilizacija za disabled stanje */

  &:disabled {
    cursor: not-allowed; /* Prikazuje da je dugme onemogućeno */
  }
`;

const ProductDiv = styled.div`
  width: 100%;

  max-height: 320px;

  overflow: auto; /* omogućava skrolanje unutar div-a */
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--text-200);
    border-radius: 5px;
  }
  background-color: var(--bg-200);
`;

const Invoice = ({ address, setSubmit, paymentMethod, shippingMethod }) => {
  const { t } = useTranslation();
  const { userProfile, authTokens } = useContext(AuthUserContext);
  const { currencyTag, createOrder, setCreatedOrder } =
    useContext(ProductContext);
  const [checkbox, setCheckbox] = useState(false);
  const navigate = useNavigate();
  const { goToThankYou } = useNavigation();

  const cartItems = useSelector((state) => state.cart.itemsList);
  let totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const subtotal = cartItems.reduce(
    (total, item) => total + item.product.discount_price * item.quantity,
    0
  );
  const subtotalRounded = parseFloat(subtotal.toFixed(2));
  const savings = cartItems.reduce(
    (totalSavings, item) =>
      totalSavings +
      (item.product.price - item.product.discount_price) * item.quantity,
    0
  );
  const savingsRounded = parseFloat(savings.toFixed(2));

  const isShippingValid =
    shippingMethod && Object.keys(shippingMethod).length > 0;

  const grandTotal = isShippingValid
    ? subtotal + getTransportPrice(shippingMethod, totalQuantity)
    : subtotal;

  const grandTotalWithTax = grandTotal * 1.2;
  const grandTotalRounded = grandTotalWithTax.toFixed(2);
  const taxAmount = (grandTotal * 0.2).toFixed(2);

  const handleSubmit = async () => {
    if (userProfile && !userProfile.is_email_verified && !checkbox) {
      return;
    }
    setSubmit(true);
    // Kreiranje objekta sa podacima narudžbine
    const orderData = {
      address: address.id,
      order_items: cartItems.map((item) => ({
        product: item.product.id,
        quantity: item.quantity,
      })),
      payment_method: paymentMethod,
      transport_method: shippingMethod.name,
      note: "",
    };
    try {
      // Poziv funkcije za kreiranje narudžbine (pretpostavljamo da funkcija `createAddressBook` postoji)
      const createdOrderCopy = await createOrder(orderData, authTokens.access);
      console.log("Order created successfully", createdOrderCopy);
      setCreatedOrder(createdOrderCopy);
      // Ovde dodajte logiku koja se izvršava nakon uspešnog kreiranja narudžbine
      const totalPrice = cartItems.reduce(
        (total, item) => total + item.product.discount_price * item.quantity,
        0
      );
      const currency = localStorage.getItem("currency") || DEFAULT_CURRENCY;

      const items = cartItems.map((item, index) => ({
        item_id: item.product.id,
        item_name: item.product.category_name + " " + item.product.name,
        affiliation: "Snusco-AT", // Customize as needed
        discount:
          Number(item.product.price) - Number(item.product.discount_price),
        index: index,
        item_category: item.product.category_name,
        item_variant: item.product.nicotine + " MG",
        price: item.product.discount_price,
        quantity: item.quantity,
      }));
      const user_info = jwtDecode(authTokens.access);

      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: "purchase",
      //     transaction_id: `T_${new Date().getTime()}`,
      //     value: totalPrice.toFixed(2),
      //     shipping: Number(paymentMethod.price).toFixed(2),
      //     currency: currency,
      //     items: items,
      //     user_id: user_info.user_id,
      //   },
      // });
      navigate(goToThankYou());
    } catch (error) {
      console.error("Greška prilikom kreiranja narudžbine:", error); // Obradite grešku
    }
    setSubmit(false);
  };
  useEffect(() => {
    console.log(
      "Invoice, paymentMethod - ",

      paymentMethod
    );
  }, [paymentMethod]);

  return (
    <Container>
      <Wrapper>
        <Header>{t("INVOICE.SUMMARY")}</Header>
        <ProductDiv>
          {cartItems.map((item, index) => (
            <InvoiceProduct item={item} key={index} />
          ))}
        </ProductDiv>
        <div style={{ width: "96%" }}>
          <FlexDiv>
            <P>{t("INVOICE.SUBTOTAL")}</P>
            <P>
              {currencyTag}
              {subtotalRounded}
            </P>
          </FlexDiv>
          <FlexDiv>
            {shippingMethod?.name ? (
              <>
                <P>
                  {t("INVOICE.SHIPPING")} - {shippingMethod.name}
                </P>
                <P>
                  {currencyTag}
                  {getTransportPrice(shippingMethod, totalQuantity).toFixed(2)}
                </P>
              </>
            ) : (
              <>
                <P>{t("INVOICE.SHIPPING")} </P>
                <P>{t("INVOICE.SHIPPING_TBD")}</P>
              </>
            )}
          </FlexDiv>
          <FlexDiv>
            <P>{t("INVOICE.TAX")} (20%) </P>
            <P>
              {" "}
              {currencyTag}
              {taxAmount}{" "}
            </P>
            {/* <P>{t("INVOICE.SHIPPING_TBD")}</P> */}
          </FlexDiv>
          <FlexDiv>
            <h2>{t("INVOICE.TOTAL_AMOUNT")} </h2>
            <h2>
              {currencyTag}
              {grandTotalRounded}
            </h2>
          </FlexDiv>
          {address && paymentMethod && (
            <>
              <FlexDiv
                style={{ gap: "4px", cursor: "pointer", marginTop: "14px" }}
                onClick={() => setCheckbox(!checkbox)}
              >
                <svg
                  width="40px"
                  height="40px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: "4px",
                  }}
                >
                  <path
                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                    stroke="var(--text-200)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="var(--background-100)"
                  />
                  {checkbox && (
                    <path
                      d="M7.75 12L10.58 14.83L16.25 9.17004"
                      stroke="var(--text-200)"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  )}
                </svg>
                <P>{t("INVOICE.AGREE_TERMS")}</P>
              </FlexDiv>
              {paymentMethod === paymentMethods[0].code && (
                <Submit onClick={handleSubmit} disabled={!checkbox}>
                  {t("INVOICE.DELIVER")}
                </Submit>
              )}
            </>
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

export default Invoice;
