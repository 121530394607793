import { useState } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../../components/section/BreadCrumbs";
import { useTranslation } from "react-i18next";

const Container = styled.main``;

const SupportContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MaxWidthWrapper = styled.div`
  margin: var(--spacing-md) 0;
  width: calc(100% - var(--spacing-xl) * 2);
  @media (min-width: 1025px) {
    width: var(--max-width-container);
    //width: 800px;
  }
`;

const Text = styled.p`
  color: var(--text-200);
  font-size: 18px;
  line-height: 1.6;
  font-weight: 600;
`;

const Title = styled.h3`
  font-size: var(--font-size-xlarge);
  margin: 0;
  margin-top: var(--spacing-lg);
  font-weight: 600;
  @media (min-width: 1025px) {
    font-size: var(--font-size-xxlarge);
  }
`;
const TitleSmall = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  color: var(--text-100);
`;

const BreadcrumbsContainer = styled.div`
  @media (min-width: 1025px) {
    width: var(--max-width-container);
  }
  width: 94%;
`;

const ReturnAndRefundPolicyMain = () => {
  const { t, i18n } = useTranslation();

  const [breadcrumbItems] = useState([
    { name: "SnusCo", url: "/" },
    {
      name: "Return and Refund policy",
      url: `/${i18n.language}/return-and-refund-policy`,
    },
  ]);

  return (
    <Container>
      <SupportContainer>
        <MaxWidthWrapper>
          <BreadcrumbsContainer className="header-top-padding">
            <Breadcrumbs breadcrumbs={breadcrumbItems} />
          </BreadcrumbsContainer>

          <Title>Return and Refund policy</Title>
          <TitleSmall>Return and refund policy</TitleSmall>
          <Text>
            Welcome to Northerner.com! We are dedicated to providing you with
            top-quality nicotine pouches and tobacco-free alternatives. Our
            commitment is not only to deliver exceptional products but also to
            ensure your satisfaction with every purchase. Below is our
            comprehensive Return and Refund Policy:
          </Text>

          <TitleSmall>Quality Concerns:</TitleSmall>
          <Text>
            Nicotine pouches typically boast an extended shelf life, maintaining
            their quality for an extended period. However, we understand that
            unforeseen circumstances may arise. If you encounter any product
            quality issues, please contact us promptly. We are here to assist
            you in resolving any concerns and ensuring your satisfaction.
          </Text>
          <TitleSmall>Order Discrepancies:</TitleSmall>
          <Text>
            In the rare instance of order discrepancies, such as incorrect
            quantities or the wrong products received, we guarantee your
            entitlement to a corrected order or the option to request a refund.
            Your satisfaction is our priority, and we are committed to swiftly
            addressing and rectifying any issues that may arise.
          </Text>
          <TitleSmall>Complaints and Further Assistance:</TitleSmall>
          <Text>
            For more detailed information regarding complaints and dispute
            resolution, please refer to our Terms & Conditions page. We strive
            to provide transparency and clarity on how we handle various
            situations to ensure a positive customer experience.
          </Text>
          <TitleSmall>Adult Signature Requirement (ASR) Orders:</TitleSmall>
          <Text>
            Certain orders may necessitate an Adult Signature Requirement (ASR)
            upon delivery. In such cases, our shipping carrier will attempt
            delivery three times. If unsuccessful, the package will be returned
            to us. Should a reshipment be required, kindly note that shipping
            costs will apply. Reach out to us directly to arrange any necessary
            steps.
          </Text>
          <Text>
            At SnusCo.com, we value your trust and aim to provide exceptional
            customer service. If you have any questions or concerns not
            addressed here, please do not hesitate to contact us. Thank you for
            choosing us as your preferred online retailer.
          </Text>
        </MaxWidthWrapper>
      </SupportContainer>
    </Container>
  );
};

export default ReturnAndRefundPolicyMain;
